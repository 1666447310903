import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { useUserContext } from "../../context/UserContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { usePaginatedApiRequest } from "../../hooks/usePaginatedApiRequest";
import { useApiClient } from "../../hooks/useApiClient";
import { Card } from "../../components/Card/Card";
import DateUtil from "../../util/DateUtil";
import { useToast } from "../../hooks/useToast";
import { PreviewButton } from "../../components/Utilities/PreviewButton";

export const CherryPayMembershipApplication = () => {
  const apiClient = useApiClient();
  const business = useBusinessContext();
  const { userBusinessId } = useUserContext();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [registration, setRegistration] = useState<string>();
  const [filter, setFilter] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [isFinalised, setIsFinalised] = useState<boolean>();
  const { showErrorToast, showSuccessToast } = useToast();

  const {
    data: registrations,
    error: registrationsError,
    isLoading: registrationsLoading,
  } = useApiRequest(
    (apiClient) =>
      apiClient.getRegistrationFormConfigurationSummaryItems(
        business.BusinessId
      ),
    [business]
  );

  const {
    items: cards,
    isLoading: reportLoading,
    isLoadingPage: reportLoadingPage,
    error: reportError,
    fetchNextPage,
  } = usePaginatedApiRequest(
    (apiClient) => {
      const start = DateUtil.setStartEndDate(startDate, "start");
      const end = DateUtil.setStartEndDate(endDate, "end");
      return apiClient.getMembershipApplicationReports(business.BusinessId, {
        limit,
        registrationFormConfigurationId: registration,
        startDate: start,
        endDate: end,
        isFinalised,
      });
    },
    (apiClient, continuationToken) => {
      const start = DateUtil.setStartEndDate(startDate, "start");
      const end = DateUtil.setStartEndDate(endDate, "end");
      return apiClient.getMembershipApplicationReports(business.BusinessId, {
        limit,
        registrationFormConfigurationId: registration,
        startDate: start,
        endDate: end,
        continuationToken: continuationToken,
        isFinalised,
      });
    },
    [business, filter]
  );

  const programTypeOptions = useMemo(
    () =>
      registrations?.map((p) => {
        return (
          <option value={p.Meta?.Id!} key={p.Meta?.Id}>
            {p.DisplayName}
          </option>
        );
      }),
    [registrations]
  );

  const cardRows = useMemo(
    () =>
      cards?.map((c) => {
        const requestSubmittedDateTime = DateUtil.getLocalDateFormatFromString(
          c.RequestSubmittedDateTime
        );
        const finalisedDate = DateUtil.getLocalDateFormatFromString(
          c.FinalisedDate
        );
        const dob = DateUtil.formatDate(c.BirthDate, undefined, {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        return (
          <Tr key={c.SessionId}>
            <Td whiteSpace="nowrap">{c.SessionId}</Td>
            <Td whiteSpace="nowrap">{requestSubmittedDateTime}</Td>
            <Td whiteSpace="nowrap">{c.RegistrationFormConfigurationName}</Td>
            <Td whiteSpace="nowrap">
              {c.MembershipNumber && (
                <PreviewButton
                  hover={true}
                  color="gray.600"
                  linkProps={{ target: "_self" }}
                  url={`/businesses/${business.BusinessId}/members/${c.MemberId}`}
                >
                  {c.MembershipNumber}
                </PreviewButton>
              )}
            </Td>
            <Td whiteSpace="nowrap">{c.FirstName}</Td>
            <Td whiteSpace="nowrap">{c.LastName}</Td>
            <Td whiteSpace="nowrap">{dob}</Td>
            <Td whiteSpace="nowrap">{c.MobileNumber}</Td>
            <Td whiteSpace="nowrap">{c.EmailAddress}</Td>
            <Td whiteSpace="nowrap">{c.SubmissionAccepted}</Td>
            <Td whiteSpace="nowrap">{finalisedDate}</Td>
            <Td whiteSpace="nowrap">{c.FinalisedByUser}</Td>
          </Tr>
        );
      }),
    [cards]
  );

  const run = () => {
    setFilter(filter + 1);
  };

  const downloadCsv = async () => {
    const start = DateUtil.setStartEndDate(startDate, "start");
    const end = DateUtil.setStartEndDate(endDate, "end");
    const qresult = apiClient.getMembershipApplicationReportCsv(
      business.BusinessId,
      registration,
      start,
      end
    );

    qresult.then((result) => {
      if (result.ok) {
        const blobData = new Blob([result.data]);
        const url = URL.createObjectURL(blobData);
        const link = document.createElement("a");
        link.download = `cherrypay-membership-application-${
          business.BusinessId
        }-${registration ?? ""}-${startDate ?? ""}-${endDate ?? ""}.csv`;
        link.href = url;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showSuccessToast("The report has been downloaded successfully");
      } else if (result.statusCode == 404) showErrorToast(result.message);
      else {
        showErrorToast("Failed to download the report");
      }
    });
  };

  return (
    <>
      <PageHeading>
        <Breadcrumb>
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/businesses">
                Businesses
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/businesses/${business.BusinessId}`}>
              {business.DisplayName}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${business.BusinessId}/reports`}
            >
              Reports
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageHeading.Title>Membership Application Report</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <VStack alignItems="start" width="100%">
          <Card>
            <Flex gridGap={6} className="form-filter-wrapper">
              {registrations && registrations.length > 0 && (
                <Box>
                  <FormControl>
                    <FormLabel>Application Form</FormLabel>
                    <Select
                      placeholder="All application forms"
                      onChange={(e) => setRegistration(e.target.value)}
                      defaultValue={registration}
                    >
                      {programTypeOptions}
                    </Select>
                  </FormControl>
                </Box>
              )}

              {registrationsLoading && (
                <Center>
                  <Spinner />
                </Center>
              )}
              {registrationsError && (
                <ErrorMessage>
                  An error was encountered while loading the program types.
                </ErrorMessage>
              )}
              <Box>
                <FormControl>
                  <FormLabel>Request Submitted from</FormLabel>
                  <Input
                    type="date"
                    placeholder="Request Submitted from"
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel>Request Submitted to</FormLabel>
                  <Input
                    type="date"
                    placeholder="Request Submitted to"
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel>Is Finalised</FormLabel>
                  <Select
                    onChange={(e) =>
                      setIsFinalised(
                        e.target.value === "All"
                          ? undefined
                          : e.target.value == "true"
                          ? true
                          : false
                      )
                    }
                    value={
                      isFinalised === undefined
                        ? undefined
                        : isFinalised
                        ? "true"
                        : "false"
                    }
                  >
                    <option value={undefined}>All</option>
                    <option value="true">Finalised</option>
                    <option value="false">Not Finalised</option>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel>Rows</FormLabel>
                  <Select
                    onChange={(e) => setLimit(parseInt(e.target.value))}
                    value={limit}
                    width="20"
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </Select>
                </FormControl>
              </Box>
              <Box alignSelf="flex-end" className="form-filter-button-wrapper">
                <Button colorScheme="cherryButton" onClick={run}>
                  Run
                </Button>
                <Button marginLeft="4" onClick={downloadCsv}>
                  Download
                </Button>
              </Box>
            </Flex>
          </Card>
          <Card width="100%">
            {!cards && reportLoading && (
              <Center>
                <Spinner margin="4" />
              </Center>
            )}
            <Box width="100%">
              <TableContainer alignSelf="start" width="100%" overflowX="auto">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th whiteSpace="nowrap">Session Id</Th>
                      <Th whiteSpace="nowrap">Request Submitted Date Time</Th>
                      <Th whiteSpace="nowrap">Application Form</Th>
                      <Th whiteSpace="nowrap">Membership Number</Th>
                      <Th whiteSpace="nowrap">First Name</Th>
                      <Th whiteSpace="nowrap">Last Name</Th>
                      <Th whiteSpace="nowrap">Birth Date</Th>
                      <Th whiteSpace="nowrap">Mobile Number</Th>
                      <Th whiteSpace="nowrap">Email Address</Th>
                      <Th whiteSpace="nowrap">Submission Accepted</Th>
                      <Th whiteSpace="nowrap">Finalised Date</Th>
                      <Th whiteSpace="nowrap">Finalised By User</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {(cards?.length && cardRows) || (
                      <Tr>
                        <Td colSpan={11} fontStyle="italic" textAlign="center">
                          No membership application data found
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Card>
          {fetchNextPage && (
            <Box>
              {!reportLoadingPage && (
                <Button colorScheme="cherryButton" onClick={fetchNextPage}>
                  Load more...
                </Button>
              )}
              {reportLoadingPage && <Spinner />}
            </Box>
          )}
          {reportError != null && (
            <ErrorMessage>
              An error was encountered while getting report. {reportError}
            </ErrorMessage>
          )}
        </VStack>
      </PageContent>
    </>
  );
};

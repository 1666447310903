import { CherryPayApi } from "./models";
import axios, { Axios, AxiosResponse } from "axios";
import { User } from "firebase/auth";
import DateUtil from "../util/DateUtil";

export type ApiError = { statusCode?: number; message: string; ok: false };
export type ApiResult<T> =
  | { statusCode: number; data: T; ok: true; continuationToken?: string }
  | ApiError;

const apiResult = <T>(
  axiosResponse: AxiosResponse,
  getData?: (data: any) => T
): ApiResult<T> => {
  if (axiosResponse.status >= 200 && axiosResponse.status <= 299) {
    // If the response body is paginated we'll find a url for the next page.
    // change approach to pass continuation token instead of relative url, there's an issue with encoding url with date/time
    // const next = axiosResponse.data?._links?.next?.relativeUrl ?? undefined;
    const continuationToken =
      axiosResponse.data?._links?.next?.continuationToken ?? undefined;

    return {
      data: (getData ? getData(axiosResponse.data) : axiosResponse.data) as T,
      statusCode: axiosResponse.status,
      ok: true,
      continuationToken,
    };
  } else if (axiosResponse.status) {
    return {
      statusCode: axiosResponse.status,
      message:
        axiosResponse.data?.ErrorMessage ??
        `HTTP ${axiosResponse.status} error.`,
      ok: false,
    };
  } else {
    return {
      statusCode: axiosResponse.status,
      message: `Unknown error.`,
      ok: false,
    };
  }
};

export class ApiClient {
  cherryPayClient: Axios;

  constructor(user: User) {
    this.cherryPayClient = axios.create({
      baseURL: `${API_BASE_URL}`,
      // HTTP errors will not throw exceptions
      validateStatus: () => true,
    });

    this.cherryPayClient.interceptors.request.use((config) => {
      // Fetches the current access token. If it has expired or is close to exiring a new token will be fetched.
      return user.getIdTokenResult().then((idToken) => {
        if (!config.headers) {
          config.headers = {};
        }
        config.headers!.Authorization = `Bearer ${idToken.token}`;
        return config;
      });
    });
  }

  async getCardTransactionSummary(
    businessId: string,
    cardId: string,
    limit: number,
    continuationToken?: string
  ): Promise<ApiResult<CherryPayApi.CherryPayCardTransactionResult>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/cards/${cardId}/transactions`,
      {
        params: {
          limit,
          continuationToken: continuationToken,
        },
      }
    );
    return apiResult<CherryPayApi.CherryPayCardTransactionResult>(result);
  }

  async changeCardAccountStatus(
    businessId: string,
    cardId: string,
    status: string
  ): Promise<ApiResult<CherryPayApi.CherryPayCardAccountStatus>> {
    const result = await this.cherryPayClient.post(
      `/cherrypay/v1/${businessId}/cards/${cardId}/set-account-status/${status}`
    );
    return apiResult<CherryPayApi.CherryPayCardAccountStatus>(result);
  }

  async getCardDetailById(
    businessId: string,
    cardId: string
  ): Promise<ApiResult<CherryPayApi.CherryPayCardCompact>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/cards/${cardId}`
    );
    return apiResult<CherryPayApi.CherryPayCardCompact>(result);
  }

  async getCardAccountStatus(
    businessId: string,
    cardId: string
  ): Promise<ApiResult<CherryPayApi.CherryPayCardAccountStatus>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/cards/${cardId}/account-status`
    );
    return apiResult<CherryPayApi.CherryPayCardAccountStatus>(result);
  }

  /**
   * @param businessId
   * @returns configurations for business, such as card program types
   */
  async getConfiguration(
    businessId: string
  ): Promise<ApiResult<CherryPayApi.BusinessConfiguration>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/configuration`
    );
    return apiResult<CherryPayApi.BusinessConfiguration>(result);
  }

  async getNewCardOrder(
    businessId: string,
    memberId: string,
    cardProgramId: string,
    signal?: AbortSignal
  ): Promise<ApiResult<CherryPayApi.CardOrderResponse>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}/cards/orders/new-order/${cardProgramId}`,
      { signal }
    );
    return apiResult<CherryPayApi.CardOrderResponse>(result);
  }

  async putCardOrderMemberDetail(
    businessId: string,
    memberId: string,
    orderId: string,
    data: CherryPayApi.CardOrderMemberDetailsUpdate
  ): Promise<ApiResult<CherryPayApi.CardOrderResponse>> {
    const result = await this.cherryPayClient.put(
      `/cherrypay/v1/${businessId}/members/${memberId}/cards/orders/${orderId}/member-details`,
      data
    );
    return apiResult<CherryPayApi.CardOrderResponse>(result);
  }

  async confirmCardOrder(
    businessId: string,
    memberId: string,
    orderId: string
  ): Promise<ApiResult<CherryPayApi.CardOrderResponse>> {
    const result = await this.cherryPayClient.post(
      `/cherrypay/v1/${businessId}/members/${memberId}/cards/orders/${orderId}/confirm`
    );
    return apiResult<CherryPayApi.CardOrderResponse>(result);
  }

  async getCardMembersReport(
    businessId: string,
    limit: number,
    cardProgramId?: string,
    startDate?: string,
    endDate?: string,
    continuationToken?: string
  ): Promise<ApiResult<CherryPayApi.CherryPayCardsByMemberReportResult>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/reports/cards/cherrypay-cards-by-member`,
      {
        params: {
          limit,
          startDate,
          endDate,
          cardProgramId,
          continuationToken,
        },
      }
    );
    return apiResult<CherryPayApi.CherryPayCardsByMemberReportResult>(result);
  }

  async getCardSummary(
    businessId: string,
    cardProgramId?: string,
    createdFrom?: string,
    createdTo?: string,
    limit?: number,
    q?: string,
    continuationToken?: string
  ): Promise<ApiResult<CherryPayApi.CherryPayCardSummaryResult>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/cards`,
      {
        params: {
          createdFromDate: createdFrom,
          createdToDate: createdTo,
          cardProgramId,
          q,
          limit,
          continuationToken,
        },
      }
    );
    return apiResult<CherryPayApi.CherryPayCardSummaryResult>(result);
  }

  async getCardSummaryPage(
    url: string
  ): Promise<ApiResult<CherryPayApi.CherryPayCardSummaryResult>> {
    const result = await this.cherryPayClient.get(url);
    return apiResult<CherryPayApi.CherryPayCardSummaryResult>(result);
  }

  async getCardMembersReportPage(
    url: string
  ): Promise<ApiResult<CherryPayApi.CherryPayCardsByMemberReportResult>> {
    const result = await this.cherryPayClient.get(url);
    return apiResult<CherryPayApi.CherryPayCardsByMemberReportResult>(result);
  }

  async getCardByMembersReport(
    businessId: string,
    cardProgramId?: string,
    startDate?: string,
    endDate?: string
  ) {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/reports/cards/cherrypay-cards-by-member/csv`,
      {
        params: {
          startDate,
          endDate,
          cardProgramId,
        },
      }
    );

    return apiResult<any>(result);
  }

  async getDisbursementAccountStatus(
    businessId: string
  ): Promise<ApiResult<CherryPayApi.CherryPayDisbursementAccountStatus>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/reports/cards/disbursement-account-status`
    );
    return apiResult<CherryPayApi.CherryPayDisbursementAccountStatus>(result);
  }

  async getCardsKpiAll(
    businessId: string
  ): Promise<ApiResult<CherryPayApi.CherryPayCardsKpiAll>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/reports/cards/kpi/all`
    );
    return apiResult<CherryPayApi.CherryPayCardsKpiAll>(result);
  }

  async getDisbursementAccTransactionReportCsv(businessId: string) {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/reports/cards/disbursement-account-transactions/csv`
    );

    return apiResult<any>(result);
  }

  async getDisbursementAccTransactionsReport(
    businessId: string
  ): Promise<
    ApiResult<CherryPayApi.CherryPayDisbursementAccTransactionReportResult>
  > {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/reports/cards/disbursement-account-transactions`
    );
    return apiResult<CherryPayApi.CherryPayDisbursementAccTransactionReportResult>(
      result
    );
  }

  async getCurrentUser(): Promise<ApiResult<any>> {
    const result = await this.cherryPayClient.get("/cherrypay/v1/me");
    return apiResult(result);
  }

  /**
   * Returns a list of venues the current user has permission to manage.
   * @returns
   */
  async getBusinesses(
    businessId?: string
  ): Promise<ApiResult<CherryPayApi.BusinessSummary[]>> {
    const result = await this.cherryPayClient.get(
      "/cherrypay/v1/business/summary",
      {
        params: businessId ? { businessId } : {},
      }
    );

    return apiResult<CherryPayApi.BusinessSummary[]>(result);
  }

  /**
   * Returns an individual business summary
   * @returns
   */
  async getBusiness(
    businessId: string
  ): Promise<ApiResult<CherryPayApi.BusinessSummary | null>> {
    const result = await this.cherryPayClient.get(
      "/cherrypay/v1/business/summary",
      {
        params: { businessId },
      }
    );

    const findBusinessInResults = (data: any) =>
      data.find(
        (business: CherryPayApi.BusinessSummary) =>
          business.BusinessId === businessId
      ) ?? null;

    return apiResult<CherryPayApi.BusinessSummary | null>(
      result,
      findBusinessInResults
    );
  }

  async searchMembersQuery(
    businessId: string,
    query?: string,
    filter?: string,
    continuationToken?: string,
    limit?: number
  ): Promise<ApiResult<CherryPayApi.MemberQueryResultSetResponse>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members${filter ? filter : ""}`,
      {
        params: { q: query, limit: limit ?? 30, continuationToken },
      }
    );

    return apiResult(result);
  }

  async searchMembersPage(
    url: string
  ): Promise<ApiResult<CherryPayApi.MemberQueryResultSetResponse>> {
    const result = await this.cherryPayClient.get(url);

    return apiResult<CherryPayApi.MemberQueryResultSetResponse>(result);
  }

  async getMember(
    businessId: string,
    memberId: string,
    signal?: AbortSignal
  ): Promise<ApiResult<CherryPayApi.Member>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}`,
      { signal }
    );

    return apiResult(result, (data) => data.Results[0]);
  }

  async searchUsers(
    businessId: string,
    query: string,
    continuationToken?: string
  ): Promise<ApiResult<CherryPayApi.UserAccountQueryResultSetResponse>> {
    const queryParam = query.length > 0 ? query : undefined;

    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/user`,
      {
        params: { q: queryParam, limit: 30, continuationToken },
      }
    );

    return apiResult(result);
  }

  async searchUsersPage(
    url: string
  ): Promise<ApiResult<CherryPayApi.UserAccountQueryResultSetResponse>> {
    const result = await this.cherryPayClient.get(url);

    return apiResult(result);
  }

  async getUser(
    businessId: string,
    userUid: string
  ): Promise<ApiResult<CherryPayApi.UserAccount>> {
    const result =
      await this.cherryPayClient.get<CherryPayApi.UserAccountQueryResultSetResponse>(
        `/cherrypay/v1/${businessId}/user`,
        {
          params: { limit: 100 },
        }
      );

    const findUserInResult = (data: any) =>
      data.Results.find(
        (user: CherryPayApi.UserAccount) => user.Uid === userUid
      );

    return apiResult(result, findUserInResult);
  }

  async createUser(
    user: { Username: string; DisplayName: string; Password?: string },
    businessId: string
  ): Promise<ApiResult<CherryPayApi.UserAccount>> {
    const result = await this.cherryPayClient.post(
      `/cherrypay/v1/${businessId}/user`,
      JSON.stringify(user),
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    return apiResult(result);
  }

  async updateUserRole(
    businessId: string,
    userUid: string,
    role: string
  ): Promise<ApiResult<CherryPayApi.UserAccount>> {
    const result = await this.cherryPayClient.put(
      `/cherrypay/v1/${businessId}/user/${userUid}/role`,
      { Value: role }
    );

    return apiResult(result);
  }

  async updateUserAuthorisedVenues(
    businessId: string,
    userUid: string,
    authorisedVenues: string[]
  ): Promise<ApiResult<CherryPayApi.UserAccount>> {
    const result = await this.cherryPayClient.put(
      `/cherrypay/v1/${businessId}/user/${userUid}/authorised-venue`,
      authorisedVenues
    );

    return apiResult(result);
  }

  async deleteUser(
    businessId: string,
    userUid: string,
    userEmail: string
  ): Promise<ApiResult<CherryPayApi.UserAccount>> {
    const result = await this.cherryPayClient.delete(
      `/cherrypay/v1/${businessId}/user/${userUid}`,
      { params: { email: userEmail } }
    );

    return apiResult(result);
  }

  async getBusinessPermissions(
    businessId: string
  ): Promise<ApiResult<CherryPayApi.Permission[]>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/configuration/permission`
    );

    return apiResult(result);
  }

  async getBusinessRoles(
    businessId: string
  ): Promise<ApiResult<CherryPayApi.Role[]>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/configuration/roles`
    );

    return apiResult(result);
  }

  async updateBusinessRoles(
    businessId: string,
    roles: { Name: string }[]
  ): Promise<ApiResult<CherryPayApi.Role[]>> {
    const result = await this.cherryPayClient.put(
      `/cherrypay/v1/${businessId}/configuration/roles`,
      roles
    );

    return apiResult(result);
  }

  async lookupAbnRecord(
    method: string,
    query: string
  ): Promise<ApiResult<CherryPayApi.ABNRecord[]>> {
    if (["abn", "acn", "name"].indexOf(method) === -1) {
      throw new Error(`Invalid ABN lookup type '${method}'`);
    }

    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/business/lookup/${method}`,
      { params: { q: query } }
    );

    return apiResult(result);
  }

  async createBusiness(
    abnRecord: CherryPayApi.ABNRecord
  ): Promise<ApiResult<any>> {
    const result = await this.cherryPayClient.post(
      "/cherrypay/v1/business",
      abnRecord
    );

    return apiResult(result);
  }

  async getBusinessVenues(
    businessId: string,
    params: { q?: string; includeDeleted?: string }
  ): Promise<ApiResult<CherryPayApi.VenueDocument[]>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/venues`,
      {
        params: {
          ...params,
          limit: 100,
        },
      }
    );

    return apiResult(result, (data) => data.Results);
  }

  async updateBusinessVenue(
    businessId: string,
    venueId: string,
    venueUpdates: Partial<CherryPayApi.VenueDocument>
  ): Promise<ApiResult<CherryPayApi.VenueDocument>> {
    const result = await this.cherryPayClient.patch(
      `/cherrypay/v1/${businessId}/venues/${venueId}`,
      venueUpdates
    );

    return apiResult(result);
  }

  async createBusinessVenue(
    businessId: string,
    venue: Partial<CherryPayApi.VenueDocument>
  ): Promise<ApiResult<CherryPayApi.VenueDocument>> {
    const result = await this.cherryPayClient.post(
      `/cherrypay/v1/${businessId}/venues`,
      venue
    );

    return apiResult(result);
  }

  async deleteBusinessVenue(
    businessId: string,
    venueId: string
  ): Promise<ApiResult<any>> {
    const result = await this.cherryPayClient.delete(
      `/cherrypay/v1/${businessId}/venues/${venueId}`
    );

    return apiResult(result);
  }
  async restoreBusinessVenue(
    businessId: string,
    venueId: string
  ): Promise<ApiResult<any>> {
    const result = await this.cherryPayClient.delete(
      `/cherrypay/v1/${businessId}/venues/${venueId}`,
      { params: { undelete: "true" } }
    );

    return apiResult(result);
  }

  async getPointsRequestModalObjects(
    businessId: string,
    memberId: string
  ): Promise<
    ApiResult<{
      Member: CherryPayApi.Member;
      PointsTypes: CherryPayApi.PointsType[];
    }>
  > {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}/points-request-modal`
    );

    return apiResult(result);
  }

  async getPointsToCardTransferModalObjects(
    businessId: string,
    memberId: string
  ): Promise<
    ApiResult<{
      Member: CherryPayApi.Member;
      PointsTypes: CherryPayApi.PointsType[];
      CherryPayCards: CherryPayApi.CherryPayCardListItem[];
      Minimum: number | null;
      Maximum: number | null;
    }>
  > {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}/points-to-card-transfer-request-modal`
    );

    return apiResult(result);
  }

  async getCPCCardInviteModalObjects(
    businessId: string,
    memberId: string,
    cardProgramType: "instantgift" | "reloadable"
  ): Promise<
    ApiResult<{
      Member: CherryPayApi.Member;
      Minimum?: number | null;
      Maximum?: number | null;
      IsSMSOptional?: boolean;
    }>
  > {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}/issue-member-cherrypay-card-request-modal/${cardProgramType}`
    );

    return apiResult(result);
  }

  async getPointsBalance(
    businessId: string,
    memberId: string,
    pointsTypeId: string
  ): Promise<ApiResult<CherryPayApi.PointsBalance>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}/points/${pointsTypeId}`
    );

    return apiResult(result);
  }

  async getMemberCardList(
    businessId: string,
    memberId: string
  ): Promise<ApiResult<CherryPayApi.CherryPayCardCompact[]>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}/cards`
    );

    return apiResult(result);
  }

  async getDigitalMembershipCardList(
    businessId: string,
    memberId: string,
    continuationToken?: string
  ): Promise<
    ApiResult<CherryPayApi.CherryPassSummaryItemQueryResultSetResponse>
  > {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}/passes`,
      {
        params: { continuationToken },
      }
    );

    return apiResult(result);
  }

  async getDefaultPointsBalance(
    businessId: string,
    memberId: string
  ): Promise<ApiResult<CherryPayApi.PointsBalance>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/${memberId}/points`
    );

    return apiResult(result);
  }

  async awardPoints(
    businessId: string,
    memberId: string,
    pointsTypeId: string,
    payload: {
      PointsByDollarValue: number;
      PointsByQuantity: number;
      Note: string;
      CherryPayUserName: string;
    }
  ): Promise<ApiResult<any>> {
    const result = await this.cherryPayClient.post(
      `cherrypay/v1/${businessId}/members/${memberId}/points/${pointsTypeId}/award`,
      { ...payload }
    );

    return apiResult(result);
  }

  async getOverridableProperties(businessId: string): Promise<ApiResult<any>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/members/overridable-properties`
    );

    return apiResult(result);
  }

  async setMemberProperties(
    businessId: string,
    memberId: string,
    properties: CherryPayApi.OverriablePropertyPayload<CherryPayApi.MemberOverridableProperties>
  ): Promise<ApiResult<CherryPayApi.Member>> {
    const result = await this.cherryPayClient.patch(
      `/cherrypay/v1/${businessId}/members/${memberId}/override-property`,
      properties
    );

    return apiResult(result);
  }

  async sendNonMemberCherryPayCardInvitation(
    businessId: string,
    firstName: string,
    lastName: string,
    mobileNumber: string,
    emailAddress: string,
    initialCardValue: number,
    cpcCardType: string,
    offerExpiryDate?: string,
    plasticExpiryDate?: string,
    customInvitationSMSMessageBodyFormat?: string | null
  ): Promise<ApiResult<{}>> {
    // Add EOD on offerExpiryDate and plasticExpiryDate
    plasticExpiryDate = plasticExpiryDate + "T23:59:59.9999999Z";
    offerExpiryDate = offerExpiryDate + "T23:59:59.9999999Z";
    const result = await this.cherryPayClient.post(
      `/cherrypay/v1/${businessId}/non-members/issue/cpc/${cpcCardType}`,
      {
        FirstName: firstName,
        LastName: lastName,
        MobileNumber: mobileNumber,
        EmailAddress: emailAddress,
        InitialCardValue: initialCardValue,
        OfferExpiryDate: offerExpiryDate,
        PlasticExpiryDate: plasticExpiryDate,
        CustomInvitationSMSMessageBodyFormat:
          customInvitationSMSMessageBodyFormat,
      }
    );

    return apiResult(result);
  }

  async sendDigitalMembershipInvitation(
    businessId: string,
    memberId: string,
    contactDetailType: CherryPayApi.ContactDetailsType
  ): Promise<ApiResult<{}>> {
    const result = await this.cherryPayClient.post(
      `/cherrypay/v1/${businessId}/members/${memberId}/invite/dmc`,
      {
        Value: contactDetailType,
      }
    );

    return apiResult(result);
  }

  async sendCherryPayCardInvitation(
    businessId: string,
    memberId: string,
    contactDetailType: CherryPayApi.ContactDetailsType,
    initialCardValue: number,
    sendAppDownloadSms: boolean | null,
    cardProgramType: "instantgift" | "reloadable"
  ): Promise<ApiResult<{}>> {
    const result = await this.cherryPayClient.post(
      `/cherrypay/v1/${businessId}/members/${memberId}/issue/cpc/${cardProgramType}`,
      {
        MemberContactDetailsType: contactDetailType,
        InitialCardValue: initialCardValue,
        SendAppDownloadSMS: sendAppDownloadSms,
      }
    );

    return apiResult(result);
  }

  async transferPointsToCard(
    businessId: string,
    memberId: string,
    cardId: string,
    pointsTypeId: string,
    payload: {
      PointsByDollarValue: number;
      PointsByQuantity: number;
      Note: string;
      Description: string;
      CherryPayUserName: string;
    }
  ): Promise<ApiResult<any>> {
    const result = await this.cherryPayClient.post(
      `cherrypay/v1/${businessId}/members/${memberId}/cards/${cardId}/funds/transfer-points/${pointsTypeId}`,
      { ...payload }
    );

    return apiResult(result);
  }

  async getPassConfig(
    businessId: string,
    passConfigurationId: string
  ): Promise<ApiResult<any>> {
    const url = `cherryplay/v1/${businessId}/pass-configurations/${passConfigurationId}`;
    const result = await this.cherryPayClient.get(url);

    return apiResult(result);
  }

  async updatePassConfig(
    businessId: string,
    passConfigurationId: string,
    updatedDoc: any
  ): Promise<ApiResult<any>> {
    const url = `cherryplay/v1/${businessId}/pass-configurations/${passConfigurationId}`;
    if (!updatedDoc.isUnprocessed) {
      throw new Error("Pass Document needs to be unprocessed.");
    }

    delete updatedDoc.isUnprocessed;

    // make sure all auxillary fields are saved as secondary
    (updatedDoc?.iOSAdditionalFields || []).map((f: any) => {
      if (f.FieldLocation === "Auxiliary") {
        f.FieldLocation = "Secondary";
      }
    });

    const result = await this.cherryPayClient.put(url, updatedDoc);

    return apiResult(result);
  }

  async getPassImages(
    businessId: string,
    passConfigurationId: string
  ): Promise<any[]> {
    const urlTemplate = `cherryplay/v1/${businessId}/pass-configurations/${passConfigurationId}/images/{passImageType}`;
    const passImageTypes = [
      "GoogleProgramLogo",
      "GoogleHeroImage",
      "AppleIcon",
      "AppleIcon2X",
      "AppleLogo",
      "AppleLogo2X",
      "AppleStrip",
      "AppleStrip2X",
    ];

    const results = await Promise.all(
      passImageTypes.map(async (imageType) => {
        const imageData = await this.cherryPayClient.get(
          urlTemplate.replace("{passImageType}", imageType),
          {
            responseType: "arraybuffer",
          }
        );
        return {
          imageData,
          imageType,
        };
      })
    );

    return results;
  }

  async savePassImage(
    businessId: string,
    passConfigurationId: string,
    passImageType: string,
    file: File
  ): Promise<any[]> {
    const url = `cherryplay/v1/${businessId}/pass-configurations/${passConfigurationId}/images/${passImageType}`;
    const data = new FormData();
    data.set("file", file);
    return await this.cherryPayClient.post(url, data);
  }
  async removePassImage(
    businessId: string,
    passConfigurationId: string,
    passImageType: string
  ): Promise<any[]> {
    const url = `cherryplay/v1/${businessId}/pass-configurations/${passConfigurationId}/images/${passImageType}`;
    return await this.cherryPayClient.delete(url);
  }

  async getPassTokens(
    businessId: string,
    passConfigurationId: string
  ): Promise<any> {
    const url = `cherryplay/v1/${businessId}/pass-configurations/metadata/supported-token-fields?passConfigurationId=${passConfigurationId}`;
    const result = await this.cherryPayClient.get(url);
    console.log("result", result);
    const allTokens = {
      ...(result.data.Member || {}),
      ...(result.data.Messaging || {}),
      ...(result.data.Business || {}),
      ...(result.data.Venue || {}),
    };
    return allTokens;
  }

  async getMembershipRequests(
    businessId: string,
    options: {
      includeDeleted?: boolean;
      q?: string;
      limit?: number;
      startDate?: string;
      endDate?: string;
      continuationToken?: string;
      isFinalised?: boolean;
      registrationFormConfigurationId?: string;
    }
  ): Promise<ApiResult<CherryPayApi.MembershipRequestResultSet>> {
    const result = await this.cherryPayClient.get(
      `cherrypay/v1/${businessId}/odma/membership-requests`,
      {
        params: options,
      }
    );
    return apiResult<CherryPayApi.MembershipRequestResultSet>(result);
  }

  async getMembershipRequest(
    businessId: string,
    sessionId: string
  ): Promise<ApiResult<CherryPayApi.MembershipRequestDocument>> {
    const result = await this.cherryPayClient.get(
      `cherrypay/v1/${businessId}/odma/membership-requests/${sessionId}`
    );
    return apiResult(result);
  }

  async getEnumConfigurations(
    businessId: string,
    enumType: string
  ): Promise<ApiResult<CherryPayApi.EnumConfigurationItem[]>> {
    const result = await this.cherryPayClient.get(
      `cherrypay/v1/${businessId}/configuration/enums/${enumType}`
    );

    return apiResult(result);
  }

  async getMemberDataOptions(
    businessId: string,
    fieldName: string,
    venueId?: string
  ): Promise<ApiResult<CherryPayApi.ScalarValue[]>> {
    const result = await this.cherryPayClient.get(
      `cherrypay/v1/${businessId}/configuration/member-data/${fieldName}`,
      {
        params: {
          venueId: venueId,
        },
      }
    );
    return apiResult(result);
  }

  async getRegistrationFormConfigurationSummaryItems(
    businessId: string
  ): Promise<
    ApiResult<CherryPayApi.RegistrationFormConfigurationSummaryItem[]>
  > {
    const result = await this.cherryPayClient.get(
      `/configuration/${businessId}/registration-form-configuration`
    );
    return apiResult(result);
  }

  async getMembershipRenewalFormConfigurationSummaryItems(
    businessId: string
  ): Promise<
    ApiResult<CherryPayApi.MembershipRenewalFormConfigurationSummaryItem[]>
  > {
    const result = await this.cherryPayClient.get(
      `/configuration/${businessId}/membership-renewal-form-configuration`
    );
    return apiResult(result);
  }

  async getMembershipApplicationReports(
    businessId: string,
    params: {
      limit: number;
      registrationFormConfigurationId?: string;
      startDate?: string;
      endDate?: string;
      continuationToken?: string;
      isFinalised?: boolean;
    }
  ): Promise<
    ApiResult<CherryPayApi.MembershipApplicationSummaryQueryResultSet>
  > {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/reports/cards/membership-application`,
      {
        params: {
          ...params,
        },
      }
    );
    return apiResult<CherryPayApi.MembershipApplicationSummaryQueryResultSet>(
      result
    );
  }

  async getMembershipApplicationReportCsv(
    businessId: string,
    registrationFormConfigurationId?: string,
    startDate?: string,
    endDate?: string
  ) {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/reports/cards/membership-application/csv`,
      {
        params: {
          startDate,
          endDate,
          registrationFormConfigurationId,
        },
      }
    );

    return apiResult<any>(result);
  }

  async getPassesByMembers(
    businessId: string,
    params: {
      limit: number;
      continuationToken?: string;
    }
  ): Promise<ApiResult<CherryPayApi.CherryPassMemberReportItemQueryResultSet>> {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/reports/cards/passes-by-member`,
      {
        params: {
          ...params,
        },
      }
    );
    return apiResult<CherryPayApi.CherryPassMemberReportItemQueryResultSet>(
      result
    );
  }

  async getPassesByMembersCsv(businessId: string) {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/reports/cards/passes-by-member/csv`
    );

    return apiResult<any>(result);
  }

  async getMembershipRenewalRequests(
    businessId: string,
    options: {
      membershipRenewalFormConfigurationId?: string;
      q?: string;
      limit?: number;
      continuationToken?: string;
      includeDeleted?: boolean;
      startDate?: string;
      endDate?: string;
    }
  ): Promise<
    ApiResult<CherryPayApi.MembershipRenewalRequestSummaryItemQueryResultSet>
  > {
    const result = await this.cherryPayClient.get(
      `cherrypay/v1/${businessId}/odma/membership-renewal-requests`,
      {
        params: options,
      }
    );
    return apiResult<CherryPayApi.MembershipRenewalRequestSummaryItemQueryResultSet>(
      result
    );
  }

  async getMembershipRenewalApplicationReports(
    businessId: string,
    params: {
      limit: number;
      membershipRenewalFormConfigurationId?: string;
      startDate?: string;
      endDate?: string;
      continuationToken?: string;
    }
  ): Promise<
    ApiResult<CherryPayApi.MembershipRenewalRequestReportItemQueryResultSet>
  > {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/reports/cards/membership-renewal-request`,
      {
        params: {
          ...params,
        },
      }
    );
    return apiResult<CherryPayApi.MembershipRenewalRequestReportItemQueryResultSet>(
      result
    );
  }

  async getMembershipRenewalApplicationReportCsv(
    businessId: string,
    membershipRenewalFormConfigurationId?: string,
    startDate?: string,
    endDate?: string
  ) {
    const result = await this.cherryPayClient.get(
      `/cherrypay/v1/${businessId}/reports/cards/membership-renewal-request/csv`,
      {
        params: {
          startDate,
          endDate,
          membershipRenewalFormConfigurationId,
        },
      }
    );

    return apiResult<any>(result);
  }
}
